/* eslint-disable jsx-a11y/label-has-associated-control*/
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { navigate } from "gatsby";
import { RichText } from "prismic-reactjs";
import * as prismic from "@prismicio/client";
import clsx from "clsx";
import { Row, Col, Button, Image } from "react-bootstrap";
import { client as prismicClient } from "../libs/prismic";
import Layout from "../components/Layout";
import Loader from "../components/Loader";
import Hero from "../components/Hero";
import { Table, Thead, Tbody, Tr, Th, Td } from "../components/Table";
import TextInputField from "../components/TextInputField";
import {
  showAlert,
  hideAlert,
  NOT_VERIFIED_ADD_NEW_ACCOUNT,
} from "../store/reducers/alert";
import { actionTypes } from '../store/InvestorQualification/type';
import { actionTypes as appActionTypes } from '../store/App/type';
import { actionTypes as accountActionTypes } from '../store/Accounts/type';
import {
  actionTypes as dialogActionTypes,
} from "../store/Dialog/type";

import { moduleTypes } from "../store/type";
import { isMobile, getAddress, getName, getInvestCost, numberWithCommas } from "../utils";
import { ACCOUNT_TYPE } from '../Constants';
import {
  getCurrentPrice
} from "../utils";

import "../styles/pages/accounts.scss";
import AmlIdentityService from "../services/AmlIdentityService";
import AccountService from "../services/AccountService";

const amlIdentityService = new AmlIdentityService();

const accountService = new AccountService();

const Accounts = ({ profile, isLoggedIn, location, updateAccountVerification, disqualificationOfAccredited }) => {
  const [accountsDoc, setAccountsDoc] = useState();
  const [isLoading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [sort, setSort] = useState(true)
  const [keyword, setKeyword] = useState("");
  const [currentPrices, setCurrentPrices] = useState({});
  const [verifChecked, setVerifChecked] = useState(false);
  const [retryCount, setRetryCount] = useState(0);

  const dispatch = useDispatch();

  const parentAccounts = useSelector(
    (state) => state?.accounts?.parentAccounts
  );

  const loading = useSelector(
    (state) => state?.app?.loading
  );

  useEffect(() => {
    if (parentAccounts && Array.isArray(parentAccounts)) {
      const _accounts = parentAccounts.filter(el => el.hasOwnProperty('zipcode'))
      setAccounts(_accounts);
    }
  }, [parentAccounts]);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const response = await prismicClient().getSingle("accounts");
        setAccountsDoc(JSON.parse(JSON.stringify(response).replace('https://staging.opalvest.com/', '/')));

        const brandResponse = await prismicClient().get({
          predicates: prismic.predicates.at(
            "document.type",
            "opal_fund_partners"
          ),
          orderings: {
            field: "document.first_publication_date",
            direction: "desc",
          },
        });
        let _currentPrices = {};
        for (const brand of brandResponse?.results) {
          for (const opal of brand.data?.opals) {
            let key = `${brand.uid}-${opal.opal_name}`;
            _currentPrices[key] = await getCurrentPrice(opal);
          }
        };
        setCurrentPrices(_currentPrices);
      } catch (e) {
      } finally {
        setLoading(false);
      }
    }
    fetchData();
    dispatch({ type: appActionTypes.LOAD_ACCOUNTS_DATA, value: null });
    return () => dispatch(hideAlert());
  }, [dispatch]);

  const handleNewAccount = () => {
    if (!isLoggedIn) {
      // is this even correct?
      navigate("/sign-up");
    } else {
      if (accounts.filter(el => el.hasOwnProperty('approvalTemplate')).length === 0) {
        dispatch(showAlert({ type: NOT_VERIFIED_ADD_NEW_ACCOUNT }));
      } else {
        localStorage.removeItem('hideHero')
        if (accounts.length > 0 && accounts[0].type === 'ria') {
          dispatch({ type: actionTypes.UPDATE_ACTIVE_STEP, value: 'investor-type' })
        } else {
          dispatch({ type: actionTypes.UPDATE_ACTIVE_STEP, value: 'investor-type' })
        }

        navigate("/investor-qualification");
      }
    }
  };

  const addPaymentMethod = (account) => {
    dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { type: 'dialog_add_payment_account', show: true, payment: null, account } });
  }

  const deleteAccount = (account) => {
    dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { type: 'dialog_delete_account', show: true, payment: null, account } });
  }

  const handleExcelAction = (accounts) => {
    dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { type: 'dialog_excel_action', show: true, accounts } });
  }

  const capitalizeFirstLetter = (value) => {
    return value?.charAt(0)?.toUpperCase() + value?.slice(1);
  }

  const representativeAccounts = accounts.filter(el => el.type === 'ria')
  const nonRepresentativeAccounts = accounts
    .filter(el => {
      if (el.type !== 'ria') {
        if (el.type === 'individual' || el.type === 'joint' || el.type === 'sd-ira') {
          return el.legalFirstName.toLowerCase().includes(keyword.toLowerCase()) ||
            el.legalLastName.toLowerCase().includes(keyword.toLowerCase());
        }
        else {
          return el.entityName.toLowerCase().includes(keyword.toLowerCase());
        }
      }
      return false;
    })
    .sort((a, b) => {
      let aName = (a.type === 'individual' || a.type === 'joint' || a.type === 'sd-ira') ? sort ? `${a?.legalFirstName} ${a?.legalLastName}` : `${a?.legalLastName} ${a?.legalFirstName}` : a?.entityName;
      let bName = (b.type === 'individual' || b.type === 'joint' || b.type === 'sd-ira') ? sort ? `${b?.legalFirstName} ${b?.legalLastName}` : `${b?.legalLastName} ${b?.legalFirstName}` : b?.entityName;
      aName = aName.toLowerCase();
      bName = bName.toLowerCase();

      return aName.localeCompare(bName);
    })
  const ria = accounts?.[0]?.type === 'ria'
  const originalNonRiaAccounts = accounts.filter(el => el.type !== 'ria')

  const params = new URLSearchParams(location.search);
  const verified = params.get("verified");
  const addmethod = params.get("addmethod");


  const checkAccounts = async () => {
    if (accounts.length > 0 && !verifChecked) {
      if (!verified) {
        setVerifChecked(true);
        accounts.forEach(async (account) => {
          if (account.verification === "needs_review" || account.verification === "created") {
            let verification = "";
            if (account.hasOwnProperty("verifiedBusiness")) {
              verification = 'approved';
            } else {
              const info = await amlIdentityService.getInfo(account._id);
              console.log("info", info);
              if (info != null) {
                verification = info.data;
              } else {
                console.log("info set retry");
                setVerifChecked(false);
                setTimeout(() => {
                  setRetryCount((prevRetryCount) => prevRetryCount + 1);
                  console.log('retryCount', retryCount);
                }, 1000);
              }
            }

            if (verification === 'approved' && (account.type === ACCOUNT_TYPE.BUSINESS || account.type === ACCOUNT_TYPE.TRUST || account.type === ACCOUNT_TYPE.RIA)) {
              verification = "";
              const infoKYC = await amlIdentityService.getInfo(account._id + "_C");
              let verification = "";
              console.log('info2_C', infoKYC);
              if (infoKYC != null) {
                verification = infoKYC.data;
              } else {
                console.log('info2_C set retry');
                setVerifChecked(false);
                setTimeout(() => {
                  setRetryCount((prevRetryCount) => prevRetryCount + 1);
                  console.log('retryCount', retryCount);
                }, 1000);
              }
            }

            dispatch({ type: dialogActionTypes.SET_ACCOUNT, value: account })
            if (verification === 'approved') {
              dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { type: 'dialog_verification', show: true, payment: null } });
            } else if (verification === 'declined') {
              dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { type: 'dialog_failed_verification', show: true, payment: null } });
            } else if (verification === 'needs_review' || verification === "created") {
              // dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { type: 'dialog_verify_pending', show: true, payment: null } });
            } else if (verification === 'errored') {
              dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { type: 'dialog_failed_verification', show: true, payment: null } });
            }

            if (verification) {
              await accountService.updateVerificationSave(account._id, { 'verification': verification, 'isVerificationRead': '1' });
              updateAccountVerification(account, verification)
            }
          } else if (account.verification === "approved" && account.isVerificationRead == 0) {
            dispatch({ type: dialogActionTypes.SET_ACCOUNT, value: account })
            dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { type: 'dialog_verification', show: true, payment: null } });
            await accountService.updateVerificationSave(account._id, { 'verification': "approved", 'isVerificationRead': '1' });
            updateAccountVerification(account, "approved", 1);
          }
        });
      } else if (accounts.some(el => el._id === verified)) {
        setVerifChecked(true);

        const account = accounts.filter(el => el._id === verified)[0];
        dispatch({ type: dialogActionTypes.SET_ACCOUNT, value: account })
        dispatch(hideAlert())

        if (account.verification === "needs_review" || account.verification === "created") {
          let verification = "";
          if (account.hasOwnProperty("verifiedBusiness")) {
            verification = 'approved';
          } else {
            const info = await amlIdentityService.getInfo(account._id);
            console.log("ver_info", info);
            if (info != null) {
              verification = info.data;
            } else {
              console.log("ver_info set retry");
              setVerifChecked(false);
              setTimeout(() => {
                setRetryCount((prevRetryCount) => prevRetryCount + 1);
                console.log('retryCount', retryCount);
              }, 1000);
            }
          }

          if (verification === 'approved' && (account.type === ACCOUNT_TYPE.BUSINESS || account.type === ACCOUNT_TYPE.TRUST || account.type === ACCOUNT_TYPE.RIA)) {
            verification = "";
            const infoKYC = await amlIdentityService.getInfo(account._id + "_C");
            let verification = "";
            console.log('ver_info2_C', infoKYC);
            if (infoKYC != null) {
              verification = infoKYC.data;
            } else {
              console.log('ver_info2_C set retry');
              setVerifChecked(false);
              setTimeout(() => {
                setRetryCount((prevRetryCount) => prevRetryCount + 1);
                console.log('retryCount', retryCount);
              }, 1000);
            }
          }

          dispatch({ type: dialogActionTypes.SET_ACCOUNT, value: account })
          if (verification === 'approved') {
            dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { type: 'dialog_verification', show: true, payment: null } });
          } else if (verification === 'declined') {
            dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { type: 'dialog_failed_verification', show: true, payment: null } });
          } else if (verification === 'needs_review') {
            dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { type: 'dialog_verify_pending', show: true, payment: null } });
          } else if (verification === 'errored') {
            dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { type: 'dialog_failed_verification', show: true, payment: null } });
          }

          if (verification) {
            await accountService.updateVerificationSave(account._id, { 'verification': verification, 'isVerificationRead': '1' });
            updateAccountVerification(account, verification)
          }

        } else if (account.verification === "approved" && account.isVerificationRead == 0) {
          dispatch({ type: dialogActionTypes.SET_ACCOUNT, value: account })
          dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { type: 'dialog_verification', show: true, payment: null } });
          await accountService.updateVerificationSave(account._id, { 'verification': "approved", 'isVerificationRead': '1' });
          updateAccountVerification(account, "approved", 1);
        }

        // navigate('/accounts/')
      }
    }
  };

  useEffect(() => {
    checkAccounts();
  }, [verified, accounts, dispatch, retryCount])

  useEffect(() => {
    console.log('retryCount updated to:', retryCount);
  }, [retryCount]);

  useEffect(() => {
    if (disqualificationOfAccredited && accounts.length > 1) {
      dispatch(hideAlert())
    }
  }, [disqualificationOfAccredited, accounts])

  useEffect(() => {
    const _accounts = parentAccounts?.filter(el => el.hasOwnProperty('zipcode'));
    if (addmethod === '1' && _accounts.length > 0) {
      dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { type: 'dialog_add_payment_account', show: true, payment: null, account: _accounts[_accounts.length - 1] } });
      navigate('/accounts/')
    }
  }, [addmethod, parentAccounts, dispatch])

  const startVerification = (account) => {
    dispatch({ type: accountActionTypes.START_VERIFY_ACCOUNT, value: account })

    navigate('/investor-qualification/');
  }

  const editAccount = (account) => {
    dispatch({ type: accountActionTypes.EDIT_ACCOUNT, value: account })
    navigate('/accounts-edit');
  }

  const continueVerify = (account) => {
    dispatch({ type: accountActionTypes.CONTINUE_VERIFY_ACCOUNT, value: account })
    if (account.type === ACCOUNT_TYPE.INDIVIDUAL ||
      account.type === ACCOUNT_TYPE.INDIVIDUAL_JOINT ||
      account.type === ACCOUNT_TYPE.INDIVIDUAL_SD_IRA) {
      if (!account.hasOwnProperty("qualification1")) {
        dispatch({ type: actionTypes.UPDATE_ACTIVE_STEP, value: 'qualification-individual-one' });
        navigate('/investor-qualification?step=qualification-individual-one');
      } else if (!account.hasOwnProperty("qualification2")) {
        dispatch({ type: actionTypes.UPDATE_ACTIVE_STEP, value: 'qualification-individual-two' });
        navigate('/investor-qualification?step=qualification-individual-two');
      } else if (!account.hasOwnProperty("question")) {
        dispatch({ type: actionTypes.UPDATE_ACTIVE_STEP, value: 'individual-question' });
        navigate('/investor-qualification?step=individual-question');
      } else if (!account.hasOwnProperty("newIssues")) {
        dispatch({ type: actionTypes.UPDATE_ACTIVE_STEP, value: 'new-issues-certification' });
        navigate('/investor-qualification?step=new-issues-certification');
      } else if (!account.hasOwnProperty("sCert")) {
        dispatch({ type: actionTypes.UPDATE_ACTIVE_STEP, value: 'spinning-prohibition-certification' });
        navigate('/investor-qualification?step=spinning-prohibition-certification');
      } else {
        dispatch({ type: actionTypes.UPDATE_ACTIVE_STEP, value: 'individual-verification' });
        navigate('/investor-qualification?step=individual-verification');
      }
    } else if (account.type === ACCOUNT_TYPE.BUSINESS) {
      if (!account.hasOwnProperty("qualification1")) {
        dispatch({ type: actionTypes.UPDATE_ACTIVE_STEP, value: 'qualification-business-one' });
        navigate('/investor-qualification?step=qualification-business-one');
      } else if (!account.hasOwnProperty("qualification2")) {
        dispatch({ type: actionTypes.UPDATE_ACTIVE_STEP, value: 'qualification-business-two' });
        navigate('/investor-qualification?step=qualification-business-two');
      } else if (!account.hasOwnProperty("question")) {
        dispatch({ type: actionTypes.UPDATE_ACTIVE_STEP, value: 'business-question' });
        navigate('/investor-qualification?step=business-question');
      } else if (!account.hasOwnProperty("newIssues")) {
        dispatch({ type: actionTypes.UPDATE_ACTIVE_STEP, value: 'new-issues-certification' });
        navigate('/investor-qualification?step=new-issues-certification');
      } else if (!account.hasOwnProperty("sCert")) {
        dispatch({ type: actionTypes.UPDATE_ACTIVE_STEP, value: 'spinning-prohibition-certification' });
        navigate('/investor-qualification?step=spinning-prohibition-certification');
      } else {
        dispatch({ type: actionTypes.UPDATE_ACTIVE_STEP, value: 'entity-verification' });
        navigate('/investor-qualification?step=entity-verification');
      }
    } else if (account.type === ACCOUNT_TYPE.TRUST) {
      if (!account.hasOwnProperty("qualification1")) {
        dispatch({ type: actionTypes.UPDATE_ACTIVE_STEP, value: 'qualification-trust-one' });
        navigate('/investor-qualification?step=qualification-trust-one');
      } else if (!account.hasOwnProperty("qualification2")) {
        dispatch({ type: actionTypes.UPDATE_ACTIVE_STEP, value: 'qualification-trust-two' });
        navigate('/investor-qualification?step=qualification-trust-two');
      } else if (!account.hasOwnProperty("question")) {
        dispatch({ type: actionTypes.UPDATE_ACTIVE_STEP, value: 'business-question' });
        navigate('/investor-qualification?step=business-question');
      } else if (!account.hasOwnProperty("newIssues")) {
        dispatch({ type: actionTypes.UPDATE_ACTIVE_STEP, value: 'new-issues-certification' });
        navigate('/investor-qualification?step=new-issues-certification');
      } else if (!account.hasOwnProperty("sCert")) {
        dispatch({ type: actionTypes.UPDATE_ACTIVE_STEP, value: 'spinning-prohibition-certification' });
        navigate('/investor-qualification?step=spinning-prohibition-certification');
      } else {
        dispatch({ type: actionTypes.UPDATE_ACTIVE_STEP, value: 'trust-verification' });
        navigate('/investor-qualification?step=trust-verification');
      }
    } else if (account.type === ACCOUNT_TYPE.RIA) {
      if (!account.hasOwnProperty("contactInfo")) {
        dispatch({ type: actionTypes.UPDATE_ACTIVE_STEP, value: 'investor-type-ria' });
        navigate('/investor-qualification?step=investor-type-ria');
      } else {
        dispatch({ type: actionTypes.UPDATE_ACTIVE_STEP, value: 'ria-verification' });
        navigate('/investor-qualification?step=ria-verification');
      }
    }
  }

  const requalifyHandler = (account) => {
    dispatch({ type: accountActionTypes.CONTINUE_VERIFY_ACCOUNT, value: account })
    if (account.type === ACCOUNT_TYPE.INDIVIDUAL ||
      account.type === ACCOUNT_TYPE.INDIVIDUAL_JOINT ||
      account.type === ACCOUNT_TYPE.INDIVIDUAL_SD_IRA) {
      dispatch({ type: actionTypes.UPDATE_ACTIVE_STEP, value: 'qualification-individual-one' });
      navigate('/investor-qualification?step=qualification-individual-one');
    } else if (account.type === ACCOUNT_TYPE.BUSINESS) {
      dispatch({ type: actionTypes.UPDATE_ACTIVE_STEP, value: 'qualification-business-one' });
      navigate('/investor-qualification?step=qualification-business-one');
    } else if (account.type === ACCOUNT_TYPE.TRUST) {
      dispatch({ type: actionTypes.UPDATE_ACTIVE_STEP, value: 'qualification-trust-one' });
      navigate('/investor-qualification?step=qualification-trust-one');
    }
  }

  const handleManageIntegration = () => {
    dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { type: 'dialog_integration', show: true } });
  }

  const getAccountTypeName = (account) => {
    if (!account.hasOwnProperty("qualification1")) {
      return <span style={{ fontStyle: "italic" }}>Qualification Pending</span>
    }
    if (account.qualification1?.[0]?.includes("does not qualify"))
      return <div className={clsx({ "pb-35": isMobile() })}>Not qualified <span className="linkable" onClick={() => requalifyHandler(account)}><b>(requalify)</b></span></div>;


    let str = 'Accredited';
    let append = "";
    if (account.hasOwnProperty('qualification2')) {
      if (account.qualification2.hasOwnProperty("clientQuestions") && account.qualification2.clientQuestions?.length > 0) {
        append = ", QC"
      }
      if (account.qualification2.hasOwnProperty("eligibleQuestions") && account.qualification2.eligibleQuestions?.length > 0) {
        append += ", QEP"
      }
      if (append === '') {
        return 'Qualified Purchaser'
      }
    }
    return str + append;
  }

  return !isLoading ? (
    <Layout>
      <Hero path={'/accounts'} />
      <Row className="pt-80 pb-120 mw-1440 mx-auto justify-content-center padding-mobile accounts">
        <Col md={12}>
          <div className="rich-text">
            <RichText render={accountsDoc?.data?.header} />
          </div>
        </Col>
        {loading ? <Col md={12}><Loader /></Col> :
          accounts.length === 0 ? (
            <Col md={12}>
              <div className="rich-text">
                <RichText render={accountsDoc?.data?.no_accounts} />
              </div>
            </Col>
          ) : (
            <>
              {representativeAccounts.length > 0 &&
                <>
                  <Col md={12} className="title-div">
                    Registered Investment Advisor
                  </Col>
                  <Col md={12}>
                    <Table>
                      <Thead>
                        <Tr>
                          <Th style={{ width: '35%' }}>Registered Investment Advisor</Th>
                          <Th style={{ width: '20%' }}></Th>
                          <Th style={{ width: '20%' }}></Th>
                          <Th style={{ width: '25%' }}>Opalvest Representative</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {representativeAccounts.map((account) => {
                          return (
                            <Tr key={account._id}>
                              <Td className="accounts-div">
                                <div className="account-field">
                                  <label className="name-label">
                                    {`${profile.firstName} ${profile.lastName}`}
                                  </label>
                                  <label>{account.riaCompany}</label>
                                </div>
                                {/* <Image
                                  className="edit-icon"
                                  src="/assets/images/icon_edit.svg"
                                /> */}
                              </Td>
                              <Td className="type-div">
                                <div className="d-flex justify-content-between">
                                  <label><b>Opal AUM</b></label>
                                  <label>${numberWithCommas(originalNonRiaAccounts.reduce((sum, account) => {
                                    return sum + getInvestCost(account, ria, currentPrices)
                                  }, 0), false)}
                                  </label>
                                </div>
                                <div className="d-flex justify-content-between mt-20">
                                  <label><b># of Accounts</b></label>
                                  <label>{originalNonRiaAccounts.length}</label>
                                </div>
                              </Td>
                              <Td className="verify-div">
                                <div>
                                  <label><b>Member Since</b></label>
                                </div>
                                <div>
                                  {(account.verification === 'declined' || account.verification === 'errored')
                                    ? <label className="failed-label">VERIFICATION FAILED</label>
                                    : (account.verification === 'needs_review' || account.verification === "created")
                                      ? <label className="pending-label">PENDING REVIEW</label>
                                      : <label>{new Date(account.created).toLocaleDateString('en-us', { year: "numeric", month: "long" })}</label>
                                  }
                                </div>

                              </Td>
                              <Td className="action2-div">

                                <label className="action-name">
                                  Jeff Maxon
                                </label>
                                <div className="d-flex align-items-center email-div">
                                  <Image
                                    className="edit-icon"
                                    src="/assets/images/icon_email.svg"
                                  />
                                  <Button><a href="mailto:jmaxon@opalvest.com" target="_blank">jmaxon@opalvest.com</a></Button>
                                </div>
                                <div className="d-flex phone-div">
                                  <Image
                                    className="edit-icon"
                                    src="/assets/images/icon_phone.svg"
                                  />
                                  <div className="phone-button"><Button><a target="_blank" href='https://meetings.hubspot.com/jeff-maxon/introductory-call'>Schedule a call</a></Button></div>
                                </div>
                              </Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                  </Col>
                  <Col md={12}>
                    <div className="accounts-button">
                      <div className="d-flex flex-md-row flex-column" style={{ display: "grid" }} >
                        {(accounts.length > 0 && accounts[0].type.toUpperCase() === 'RIA' && accounts[0].verification === 'approved') && (
                          <div className="new-action-div">
                            <Image className="edit-icon" src="/assets/images/icon_add.svg" onClick={handleNewAccount} />
                            <Button className="name-label" onClick={handleNewAccount}>
                              Add client account
                            </Button>
                          </div>
                        )}
                        {(accounts.length > 0 && accounts[0].verification === 'approved') && (
                          <div className="new-action-div">
                            <Image className="edit-icon" src="/assets/images/icon_excel.svg" onClick={() => handleExcelAction(accounts)} />
                            <Button className="name-label" onClick={() => handleExcelAction(accounts)}>
                              Download/upload
                            </Button>
                          </div>
                        )}
                        {(accounts.length > 0 && accounts[0].verification === 'approved') && (
                          <div className="new-action-div manage-integration-div">
                            <Image className="edit-icon" src="/assets/images/intergration.svg" onClick={handleManageIntegration} />
                            <Button className="name-label" onClick={handleManageIntegration}>
                              Manage integrations
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                </>}
              {originalNonRiaAccounts.length > 2 && (
                <Col md={12} style={{ marginBottom: "10px" }}>
                  <TextInputField
                    variant="filled"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    onChange={(e) => setKeyword(e.target.value)}
                    value={keyword}
                    placeholder="Search by Name"
                    className={clsx("search-input", {"mt-0": !ria})}
                  />
                </Col>
              )}
              {nonRepresentativeAccounts.length > 0 &&
                <>
                  <Col md={12} className={clsx("title-div", "text-center", {
                    "d-none": representativeAccounts.length === 0
                  })}>
                    Investor&nbsp;&nbsp;{nonRepresentativeAccounts.length > 1 && <span onClick={() => setSort(prev => !prev)} className="clickable">{!sort ? "(First Last)" : "(Last, First)"}</span>}
                  </Col>
                  <Col md={12} className={clsx("second-table", {
                    "mt-0": representativeAccounts.length === 0
                  })}>
                    <Table>
                      <Thead>
                        <Tr>
                          <Th style={{ width: '35%' }}>Investor&nbsp;&nbsp;{nonRepresentativeAccounts.length > 1 && <span onClick={() => setSort(prev => !prev)} className="clickable">{!sort ? "(First Last)" : "(Last, First)"}</span>}</Th>
                          <Th style={{ width: '20%' }}>Account Type</Th>
                          <Th style={{ width: '20%' }}>Investor Profile</Th>
                          <Th style={{ width: '25%' }}>Payment Methods</Th>
                          <Th style={{ width: '30px' }}></Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {nonRepresentativeAccounts.map((account) => {
                          return (
                            <Tr key={account._id}>
                              <Td className="accounts-div">
                                <div className="account-field">
                                  <label className="name-label">
                                    {getName(account, sort)}
                                  </label>
                                  <label>{getAddress(account)}</label>
                                </div>
                              </Td>
                              <Td>
                                <label><b>{capitalizeFirstLetter(account.entityType === 'trust' ? 'trust' : account.type)}</b></label>
                                {!isMobile() && <br/>}
                                <label>
                                  {getAccountTypeName(account)}
                                </label>
                              </Td>
                              {(!account.qualification1?.[0]?.includes("does not qualify")) ?
                                (<Td>
                                  {
                                    account.hasOwnProperty('verification') ? (
                                      account.verification === "approved" ? (
                                        <p className="verified-p"><label>Completed</label><Button onClick={() => editAccount(account)}><Image className="edit-icon" src="/assets/images/icon_edit.svg" /></Button></p>
                                      ) : (account.verification === "needs_review" || account.verification === "created" ? (
                                        <p className="pending-label">PENDING REVIEW</p>
                                      ) : (
                                        /**  error occured */
                                        <Button onClick={() => continueVerify(account)}>Continue & verify</Button>
                                      ))
                                    ) : (
                                      <Button className={clsx({ "pb-35": isMobile() && !account.hasOwnProperty("qualification1") })} onClick={() => continueVerify(account)}>Continue & verify</Button>
                                    )
                                  }
                                </Td>) : (<Td></Td>)}
                              {(account.hasOwnProperty("qualification1") && !account.qualification1?.[0]?.includes("does not qualify")) ?
                                (<Td className={clsx("action-div", { "pb-35": isMobile() })}>
                                  {account.hasOwnProperty("qualification1") &&
                                    !isMobile() ? <><Image
                                      onClick={() => addPaymentMethod(account)}
                                      className="edit-icon"
                                      src="/assets/images/icon_add.svg"
                                    />
                                    <div className="account-method">
                                      {account?.payments?.length > 0 ? account?.payments?.map(el => <div>{el.itemId === 'wire' ? `${el.name}` : `${el.name} *${el.mask}`}</div>)
                                        : <Button className="name-label" onClick={() => addPaymentMethod(account)}>{'Add payment method'}</Button>}
                                    </div></>
                                    : <div className="account-method">
                                      {account?.payments?.map(el => <div>{el.itemId === 'wire' ? `${el.name}` : `${el.name} *${el.mask}`}</div>)}
                                      <Button className="name-label" onClick={() => addPaymentMethod(account)}>{'Add method'}</Button></div>
                                  }
                                </Td>) : <Td></Td>}
                                <Td style={{textAlign: 'right'}}>
                                {
                                  (!account.hasOwnProperty('qualification1') || account.qualification1?.[0]?.includes("does not qualify") || !account.hasOwnProperty('verification') || !(account.verification === "approved" || account.verification === "needs_review" || account.verification === "created")) && (
                                    <Button onClick={() => deleteAccount(account)}>
                                      <Image
                                        className="edit-icon"
                                        src="/assets/images/icon_delete.png"
                                      />
                                    </Button>
                                  )
                                }
                              </Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                  </Col>
                </>}
              {(accounts.length > 0 && accounts[0].type.toUpperCase() !== 'RIA') && (
                <div className="new-action-div">
                  <Image className="edit-icon" src="/assets/images/icon_add.svg" onClick={handleNewAccount} />
                  <Button className="name-label" onClick={handleNewAccount}>
                    Add new account
                  </Button>
                </div>
              )}
            </>)}
        {((!ria || representativeAccounts[0].verification === "approved") && nonRepresentativeAccounts.length === 0) && (
          <>
            <Col md={12} className={clsx("second-table no-result", {
              "mt-0": representativeAccounts.length === 0
            })}>
              <Table>
                <Thead>
                  <Tr>
                    <Th style={{ width: '35%' }}>Investor</Th>
                    <Th style={{ width: '20%' }}>Account Type</Th>
                    <Th style={{ width: '20%' }}>Verification</Th>
                    <Th style={{ width: '25%' }}>Payment Methods</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr className="nohover">
                    <Td>{originalNonRiaAccounts.length === 0 ? 'No accounts have been added.' : 'No accounts match your search.'}</Td>
                  </Tr>
                </Tbody>
              </Table>
            </Col>
          </>
        )}
      </Row>
    </Layout>
  ) : (
    <Loader />
  );
};

const mapStateToProps = (state) => ({
  state,
  profile: state.app.profile,
  isLoggedIn: state[moduleTypes.APP].isLoggedIn,
  disqualificationOfAccredited: state[moduleTypes.INVESTOR_QUALIFICATION].disqualificationOfAccredited,
})

const mapDispatchToProps = (dispatch) => {
  return {
    updateAccountVerification: (account, verification, isVerificationRead) => {
      let data = { verification };
      if (isVerificationRead) {
        data = { ...data, isVerificationRead };
      }
      dispatch({ type: accountActionTypes.UPDATE_ACCOUNT_VERIFICATION, value: {_id: account._id, data} })
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Accounts);
