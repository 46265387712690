import HttpCommon from './HttpCommon'

import { ErrorWrapper, ResponseWrapper } from './util'
export default class AmlIdentityService {
  constructor() {
    this.http = new HttpCommon('amlverification', true)
  }

  sendBusiness = async (data = {}) => {
    try {
      const response = await this.http.post('/business', data)
      return new ResponseWrapper(response).data
    } catch (error) {
      new ErrorWrapper(error)
    }
  }

  sendIndividual = async (data = {}) => {
    try {
      const response = await this.http.post('/individual', data)
      return new ResponseWrapper(response).data
    } catch (error) {
      new ErrorWrapper(error)
    }
  }

  getInfo = async (refId) => {
    try {
      const response = await this.http.get(`/get_info/${refId}`)
      return new ResponseWrapper(response).data
    } catch (error) {
      new ErrorWrapper(error)
    }
  }
}
